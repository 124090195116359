exports.components = {
  "component---src-onlabel-pages-404-js": () => import("./../../../src/onlabel-pages/404.js" /* webpackChunkName: "component---src-onlabel-pages-404-js" */),
  "component---src-onlabel-pages-about-js": () => import("./../../../src/onlabel-pages/about.js" /* webpackChunkName: "component---src-onlabel-pages-about-js" */),
  "component---src-onlabel-pages-contact-us-js": () => import("./../../../src/onlabel-pages/contact-us.js" /* webpackChunkName: "component---src-onlabel-pages-contact-us-js" */),
  "component---src-onlabel-pages-dashboard-js": () => import("./../../../src/onlabel-pages/dashboard.js" /* webpackChunkName: "component---src-onlabel-pages-dashboard-js" */),
  "component---src-onlabel-pages-documents-js": () => import("./../../../src/onlabel-pages/documents.js" /* webpackChunkName: "component---src-onlabel-pages-documents-js" */),
  "component---src-onlabel-pages-forgot-js": () => import("./../../../src/onlabel-pages/forgot.js" /* webpackChunkName: "component---src-onlabel-pages-forgot-js" */),
  "component---src-onlabel-pages-index-js": () => import("./../../../src/onlabel-pages/index.js" /* webpackChunkName: "component---src-onlabel-pages-index-js" */),
  "component---src-onlabel-pages-inquiry-detail-js": () => import("./../../../src/onlabel-pages/inquiry-detail.js" /* webpackChunkName: "component---src-onlabel-pages-inquiry-detail-js" */),
  "component---src-onlabel-pages-my-account-js": () => import("./../../../src/onlabel-pages/my-account.js" /* webpackChunkName: "component---src-onlabel-pages-my-account-js" */),
  "component---src-onlabel-pages-my-insights-js": () => import("./../../../src/onlabel-pages/my-insights.js" /* webpackChunkName: "component---src-onlabel-pages-my-insights-js" */),
  "component---src-onlabel-pages-reset-password-js": () => import("./../../../src/onlabel-pages/reset-password.js" /* webpackChunkName: "component---src-onlabel-pages-reset-password-js" */)
}

